
















import { Route } from 'vue-router'
import { stateModule, userModule } from '@/store'
import { Component, Vue } from 'vue-property-decorator'
import UserHardHat from '@/components/icons/userHardHat.vue'

import Navigation from '@/components/partials/Navigation.vue'

@Component({
  components: {
    UserHardHat,
    Navigation,
  },
})
export default class AuthIndex extends Vue {
  private showAlert: boolean = false

  private async beforeRouteEnter(to: Route, from: Route, next: any): Promise<void> {
    if (userModule.isLoggedIn) {
      if (this.$route.query.next) {
        next({ path: this.$route.query.next })
      } else {
        next({ name: 'dashboard' })
      }
    } else {
      next()
    }
  }
}
